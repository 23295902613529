import React from "react";
import { GamePeopleData } from "../../models/GamePeopleData";
import { PersonCard } from "./PersonCard";
import styles from "./PeopleGrid.module.css";
import classnames from "classnames";

interface Props {
    people: GamePeopleData[];
    setPersonEnabled: (id: string, enabled: boolean) => void;
    className: string;
}

export const PeopleGrid = (props: Props) => {
    return (
        <div className={classnames(
            props.className,
            styles.grid,
        )}>
            {props.people.map(p => (
                <PersonCard image={p.imageUrl}
                    disabled={!p.enabled}
                    key={p.id}
                    name={p.name}
                    onClick={() => {
                        props.setPersonEnabled(p.id, !p.enabled);
                    }}
                />
            ))}
        </div>
    )
}

PeopleGrid.defaultProps = {
    className: undefined,
};
