import React from "react";
import styles from "./Header.module.css";
import { Refresh, Visibility } from "@material-ui/icons";
import classnames from "classnames";

interface Props {
    onRefresh: () => void;
    onReveal: () => void;
    className: string;
}

export const Header = (props: Props) => (
    <nav className={classnames(
        props.className,
        styles.header,
    )}>
        <Visibility 
            className={classnames(
                styles.icon, 
                styles.visibility
            )}
            fontSize="large"
            onClick={props.onReveal}
        />
        <Refresh 
            className={classnames(styles.icon, styles.refresh)}
            fontSize="large"
            onClick={props.onRefresh}
        />
    </nav>
);

Header.defaultProps = {
    onRefresh: () => undefined,
    onReveal: () => undefined,
    className: undefined,
}
