import React, { useState } from "react";
import styles from "./Footer.module.css";
import classnames from "classnames";
import { FinishTurn } from "./FinishTurn";

enum ActiveSide {
    None,
    Left,
    Right,
}

interface Props {
    onTurnEndRequested: () => void;
    className: string;
}

// @TODO: Replace buttons with buttons

export const Footer = (props: Props) => {
    const [ active, setActive ] = useState(ActiveSide.None);

    return (
        <div className={classnames(
            props.className,
            styles.footer
        )}>
            <div className={classnames(
                styles["question-container"],
                styles["left"],
                { [styles["active"]]: active === ActiveSide.Left }
            )}>
                <FinishTurn 
                    onCancel={() => setActive(ActiveSide.None)}
                    onFinish={() => {
                        setActive(ActiveSide.None)
                        props.onTurnEndRequested();
                    }}
                />
            </div>
            <div 
                className={classnames(
                    styles["container"]
                )}
            >
                <div
                    className={classnames(
                        styles["button"],
                        styles["primary"],
                    )}
                    onClick={() => { 
                        setActive(ActiveSide.Left) 
                    }}
                >
                    Custom question
                </div>
                <div
                    className={classnames(
                        styles["button"],
                        styles["secondary"],
                        styles["disabled"]
                    )}
                >
                    Browse questions
                </div>
            </div>
            <div className={classnames(
                styles["question-container"],
                styles["right"],
                { [styles["active"]]: active === ActiveSide.Right }
            )}/>
        </div>
    );
}

Footer.defaultProps = {
    onTurnEndRequested: () => undefined,
    className: undefined,
};
