import React from "react";
import styles from "./TurnCounter.module.css";
import classnames from "classnames";

const suffix = [
    "st",
    "nd",
    "rd",
    "th"
];

interface Props {
    count: number;
    className: string;
}

function generateText(count: number): string {
    let s = suffix[3];

    switch (count) {
        case 1:
        case 2:
        case 3:
            s = `${suffix[count - 1]}`;
            break;
        default:
            s = `${suffix[3]}`; 
            break;
    }

    return `${count}${s} turn`;
}

export const TurnCounter = (props: Props) => (
    <div className={classnames(
        styles.container,
        props.className
    )}>
        {generateText(props.count)}
    </div>
);

TurnCounter.defaultProps = {
    className: undefined,
}
