import React from "react";
import { PersonData } from "../../models/GamePeopleData";
import { PersonCard } from "./PersonCard";
import styles from "./RevealCard.module.css";
import classnames from "classnames";

interface Props {
    self: PersonData;
    revealed: boolean;
}

export const RevealCard = (props: Props) => (
    <div className={classnames(
        styles.container,
        { [styles.revealed]: props.revealed }
    )}>
        <PersonCard 
            image={props.self.image}
            name={props.self.name}
        />
    </div>
);

RevealCard.defaultProps = {
    revealed: true,
}
