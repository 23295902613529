import React, { useState, useEffect } from "react";
import { Header } from "./Header";
//import axios from "axios";
import { TurnCounter } from "./TurnCounter";
import { createGamePeople, GamePeopleData, PersonData } from "../../models/GamePeopleData";
import { PeopleGrid } from "./PeopleGrid";
import produce from "immer";
//import { PeopleData, BASE_URL } from "../../models/PeopleData";
import { Footer } from "./Footer";
import styles from "./GamePage.module.css";
import { persons } from "../../models/Persons";
import { RevealCard } from "./RevealCard";
import _ from "lodash";

// @TODO: This code is used to talk to a backend, which we don't use atm.
//        We currently are a standalone application with images loaded in.

// type R = [ {data: PersonData[]; isLoading: boolean; isError: boolean}, React.Dispatch<React.SetStateAction<string>>];

// const useDataApi = (initialUrl: string, initialData: PersonData[]): R => {
//     const [ data, setData ] = useState(initialData);
//     const [ url, setUrl ] = useState(initialUrl);
//     const [ isLoading, setIsLoading ] = useState(false);
//     const [ isError, setIsError ] = useState(false);

//     useEffect(() => {
//         (async () => {
//             setIsError(false);
//             setIsLoading(true);

//             try {
//                 const result = await axios.get<PeopleData[]>(url, {
//                     headers: {
//                         "Accept-Language": navigator.language,
//                     }
//                 });

//                 setData(result.data
//                     .filter(p => p.name !== "Viresh" && p.name  !== "Salim")
//                     .map(({ personImageId, ...rest }) => ({
//                         ...rest,
//                         image: `${BASE_URL}/images/${personImageId}`,
//                     }))
//                 );
//             } catch (error) {
//                 setIsError(true);
//             }

//             setIsLoading(false);
//         })();
//     }, [ url ]);

//     return [ { data, isLoading, isError }, setUrl ];
// }

export const GamePageContainer = () => {
    // const [ { data } ] = useDataApi(
    //     `${BASE_URL}/people`,
    //     [],
    // );

    const data = persons;

    // @TODO: Show loading and error.

    return (
        <GamePage
            people={data}
        />
    );
}

interface Props {
    people: PersonData[];
}

export const GamePage: React.FC<Props> = (props) => {
    const [ turn, setTurn ] = useState(1);
    const [ people, setPeople ] = useState<GamePeopleData[]>([]);
    const [ reveal, setReveal ] = useState(false);
    const [ self, setSelf ] = useState(_.sample(props.people) as PersonData)

    useEffect(() => {
        setPeople(createGamePeople(props.people));
    }, [ props.people ]);

    const refresh = () => {
        setPeople(createGamePeople(props.people));
        setTurn(1);
        setSelf(_.sample(props.people) as PersonData);
    }

    const setPersonEnabled = (id: string, enabled: boolean) => {
        const state = produce(people, draft => {
            const index = people.findIndex(p => p.id === id);

            if (index >= 0) {
                draft[index].enabled = enabled;
            }
        });

        setPeople(state);
    }

    return (
        <div className={styles.container}>
            <RevealCard 
                self={self}
                revealed={reveal}
            />
            <Header 
                className={styles.header} 
                onRefresh={refresh} 
                onReveal={() => setReveal(!reveal)}
            />
            <TurnCounter 
                className={styles.counter} 
                count={turn} 
            />
            <PeopleGrid 
                className={styles.grid}
                people={people} 
                setPersonEnabled={setPersonEnabled}
            />
            <Footer 
                className={styles.footer}
                onTurnEndRequested={() => setTurn(turn + 1)}
            />
        </div>
    )
};
