import React from "react";
import styles from "./PersonCard.module.css";

interface Props {
    image: string;
    name: string;
    disabled: boolean;
    onClick: () => void;
}

export const PersonCard = (props: Props) => (
    <div 
        onClick={props.onClick}
        className={styles.container}
    >
        <img 
            alt={props.name}
            src={props.image}
            className={styles.img}
            style={{
                opacity: props.disabled ? 0.2 : 1,
            }}
        />
        <span className={styles.text}>{props.name}</span>
    </div>
);

PersonCard.defaultProps = {
    disabled: false,
    onClick: () => undefined,
}
