import React from "react";
import styles from "./FinishTurn.module.css";
import { Clear } from "@material-ui/icons";

interface Props {
    onFinish: () => void;
    onCancel: () => void;
}

// @TODO: Change buttons to buttons

export const FinishTurn = (props: Props) => (
    <div className={styles.container}>
        <div 
            id="cancel-button" 
            className={styles.cancel}
            onClick={props.onCancel}
        >
            <Clear fontSize="large"/>
        </div>
        <div id="finish-text-box" className={styles.text}>
            Select avatars to dismiss in order to end your turn.
        </div>
        <div 
            id="finish-button" 
            className={styles.finish}
            onClick={props.onFinish}>
            Finish
        </div>
    </div>
)

FinishTurn.defaultProps = {
    onFinish: () => undefined,
    onCancel: () => undefined,
}
