import { PersonData } from "./GamePeopleData";
import { Maarten, Elsje, Adriaan, Viresh, Salim, Nick, Patrick, Randy, Lejun, Michiel, Tim, Joey, Pedram, Kaj, Albert, Arvind, Jelle, Rob, Marco, Remco, Charmaine, Oskar, Chef, Mike, Henk } from "../images/Images";


const createPerson = (id: string, name: string, image: string): PersonData => ({
    id, name, image
});

const data = [
    [ "Maarten", Maarten ],
    [ "Elsje", Elsje ] ,
    [ "Adriaan", Adriaan ],
    [ "Lejun", Lejun ],
    [ "Michiel", Michiel ],
    [ "Tim", Tim ],
    [ "Randy", Randy ],
    [ "Viresh", Viresh ],
    [ "Salim", Salim ],
    [ "Joey", Joey ],
    [ "Pedram", Pedram ],
    [ "Kaj", Kaj ],
    [ "Nick", Nick ],
    [ "Albert", Albert ],
    [ "Patrick", Patrick ],
    [ "Arvind", Arvind ],
    [ "Jelle", Jelle ],
    [ "Rob", Rob ],
    [ "Marco", Marco ],
    [ "Remco", Remco ],
    [ "Charmaine", Charmaine ],
    [ "Oskar", Oskar ],
    [ "Mike", Mike ],
    [ "Chef", Chef ],
    [ "Henk", Henk ],
]

export const persons: PersonData[] = data.map(([ name, image ], index) => {
    return createPerson(`${index}`, name, image);
});
