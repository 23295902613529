import React from "react";
import "./App.css";
import { GamePageContainer } from "./pages/GamePage/GamePage";

const App: React.FC = () => {
    return (
        <GamePageContainer/>
    );
}

export default App;
