export interface GamePeopleData {
    id: string;
    name: string;
    enabled: boolean;
    imageUrl: string;
}

export interface PersonData {
    id: string;
    name: string;
    image: string;
}

export function createGamePeople(people: PersonData[]): GamePeopleData[] {
    return people.map<GamePeopleData>(p => ({
        id: p.id,
        name: p.name,
        enabled: true,
        imageUrl: p.image,
    }));
}
